<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('AccountCreate')"
    @edit="(id) => onEdit('AccountEdit', id)"
  >  
    <template v-slot:customersListButton="{ row }">
      <sm-button
        class="customers-list-button"
        @click="onOpenCustomersForAccount(row.id)"
      >
        Связанные клиенты
      </sm-button>
    </template>
  </sm-editable-list>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'Accounts',

  components: {
    SmEditableList,
    SmButton,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Accounts',
      tableCaption: 'Аккаунты',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Аккаунты',
        },
      ],
      tableHeaders: [
        { text: 'ID', alias: 'id', order: 'id' },
        { text: 'ФИО', alias: 'fio', order: 'fio' },
        { text: 'Должность', alias: 'position', order: 'position' },
        { text: 'Телефон', alias: 'phone', order: 'phone' },
        { text: 'Email', alias: 'mail', order: 'mail' },
        { text: 'Логин', alias: 'login', order: 'login' },
        { text: 'Пароль', alias: 'password', order: 'password' },
        { text: 'Создан', alias: 'created', order: 'created' },
        {
          text: 'Верификационный телефон',
          alias: 'verificatedPhone',
          order: 'verificatedPhone',
        },
        {
          text: 'Верифицирован',
          alias: 'isPhoneVerificated',
          order: 'isPhoneVerificated',
        },
        { text: 'Передана в пулл', alias: 'isPool', order: 'isPool' },
        {
          alias: 'customersListButton',
        },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },

  methods: {
    onOpenCustomersForAccount(id) {
      this.$router.push({ name: 'CustomersForAccount', params: { id } });
    },
  },
};
</script>

<style lang="scss">
.customers-list-button {
  width: auto;
  min-width: 103px;
  word-break: normal;
  text-align: center;
}
</style>
